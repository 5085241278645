
.upperBar {
    position:relative;
    margin-top:8%;
    margin-left: 25%;
    justify-content:space-between;
}
.upperBar a {
    padding-right: 50%; 
}

.links2{
    font-family: 'Times New Roman', Times, serif;
    color:#acc2d9;
    font-size: xx-large;
    text-decoration: none;
    text-indent: 50px;
    word-spacing: 30px;
}
.links2:hover {
    color:#cff3da;
}

.star{
    -webkit-user-drag: none;
    max-width:200px;
}

.image-container {
    position: relative;
    width: 100%; /* Adjust the width as needed */
    height: 10px; /* This is necessary for the padding-top trick */
    
  }
  
.image-container img {
    position: absolute;
    bottom:-20px;
    left: 50%; /* Center the image horizontally */
    transform: translate(-50%, -5%); /* Adjust the vertical position to move it 5% upwards */
    width: 100%; /* Image takes full width of its container */
    height: auto; /* Maintain the aspect ratio */
}